<template>
  <div>
    <div class="flex w-1/2 mobile:w-full mobile:px-2 mobile:mx-0 mx-auto">
      <add-company-client @clientSaved="handleAddedClient" />
    </div>
  </div>
</template>

<script>
import AddCompanyClient from "@/components/company/clients/AddCompanyClient";
export default {
  components: {
    AddCompanyClient
  },
  methods: {
    handleAddedClient(e) {
      this.$router.push(`/clients/${e}`);
    }
  }
};
</script>
