<template>
  <div class="flex flex-col pl-2 mb-3 w-full mx-auto">
    <h2 class="mb-4">Lisa klient</h2>
    <add-client-form
      :project="null"
      @clientUpdated="handleClientUpdate"
      @clientContactsUpdated="handleClientContactUpdate"
      @editingContact="blockSaving"
    ></add-client-form>
    <div class="error-box w-full items-center" v-if="$v.$error">
      <div class="w-1/12 items-center justify-center">
        <span class="typcn typcn-times text-4xl leading-none"></span>
      </div>
      <div class="w-11/12">
        <span class="flex" v-if="!$v.client.name.required"
          >Palun sisestage kliendi nimi</span
        >
        <span class="flex" v-if="!$v.client.email.email"
          >Sisestatud meiliaadress ei ole korrektne</span
        >
      </div>
    </div>
    <div class="flex flex-row justify-center mt-8" v-if="!saveBlock">
      <button
        class="mr-3"
        :class="$v.$invalid ? 'new-button-disabled' : 'new-button-green'"
        @click="
          $v.$invalid
            ? () => {
                $v.$touch();
              }
            : saveClient()
        "
      >
        <span class="icon typcn typcn-tick"></span>
        <span class="label">Salvesta</span>
      </button>
      <router-link to="/clients">
        <button class="new-button-danger ml-3" @click="close">
          <span class="typcn-times typcn icon"></span>
          <span class="label">Tühista</span>
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
import AddClientForm from "@/components/company/clients/AddClientForm";
import {
  maxLength,
  minLength,
  required,
  email
} from "vuelidate/lib/validators";
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  name: "AddCompanyClient",
  components: {
    AddClientForm
  },
  mixins: [RequestHandler],

  data() {
    return {
      isCompany: false,
      error: false,
      countryCode: "+372",
      phoneNumberInput: "",
      companyPhoneNumberInput: "",
      emailInput: "",
      clientCompanyEmailInput: "",
      client: {
        name: null,
        email: null,
        telephone_number: null,
        address: null,
        register_code: null,
        is_company: false,
        tax_code: null
      },
      contactDetails: null,
      clientContacts: [],
      saveBlock: false
    };
  },
  watch: {
    phoneNumberInput() {
      const test = new RegExp(/[a-zA-Z\s]/);
      if (this.phoneNumberInput !== null) {
        this.phoneNumberInput = this.phoneNumberInput.replace(test, "");
      }
    },
    emailInput() {
      this.client.email = this.emailLowercased;
    }
  },
  computed: {
    emailLowercased() {
      return this.emailInput.toLowerCase();
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    saveClientContact(contact) {
      this.clientContacts.push(contact);
    },
    handleClientUpdate(e) {
      this.client = e;
    },
    handleClientContactUpdate(e) {
      this.clientContacts = e;
    },
    blockSaving(e) {
      this.saveBlock = e;
    },
    saveClient() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.phoneNumberInput.length > 0) {
          this.client.telephone_number =
            this.countryCode + this.phoneNumberInput;
        }
        this.apiRequest(
          "company/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/clients/add/",
          "post",
          true,
          this.client
        ).then(res => {
          if (res.status === 200) {
            let clientId = res.data.client_id;

            if (this.clientContacts.length > 0) {
              this.saveClientContacts(clientId);
            } else {
              this.$emit("clientSaved", clientId);
            }
          }
        });
      }
    },
    saveClientContacts(clientId) {
      this.apiRequest(
        "/company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/client/" +
          clientId +
          "/contacts/add/",
        "post",
        true,
        this.clientContacts
      ).then(res => {
        if (res.status === 200) {
          this.$emit("clientSaved", clientId);
        }
      });
    }
  },
  validations() {
    if (this.phoneNumberInput !== null && this.phoneNumberInput.length > 0) {
      return {
        client: {
          name: {
            required
          },
          email: {
            email
          }
        },
        phoneNumberInput: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(8)
        }
      };
    } else {
      return {
        client: {
          name: {
            required
          },
          email: {
            email
          },
          company: {
            email: {
              email
            }
          }
        }
      };
    }
  }
};
</script>
